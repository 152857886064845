<template>
  <div class="payment-hystory">
    <div class="component-header">
      <span>{{ $route.name }}</span>
    </div>

    <a-table :columns="columns" :data-source="data" :pagination="pagination">
      <span slot="date" class="customTitle">Дата оплаты</span>
      <span slot="sum" class="customTitle">Сумма</span>
      <span slot="rate" class="customTitle">Тариф</span>
      <span slot="restaurant" class="customTitle">Локация</span>
      <span slot="logo" class="customTitle">Логотип</span>

      <span slot="dateScope" slot-scope="date"
        >{{ date.format("DD.MM.YY HH.mm") }}
      </span>
      <img slot="logoScope" slot-scope="url" :src="url" height="60" />
      <span slot="settingsScope" slot-scope="settings, record">
        <a-button type="link" @click="editClick(record.key)">
          <IconBase width="16" height="16" icon-name="редактировать"
            ><IconEditGear
          /></IconBase>
        </a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

import logoImg from "@/assets/restourant.png";
import IconBase from "./IconBase";
import IconEditGear from "./IconBase/IconEditGear";

const columns = [
  {
    slots: { title: "date" },
    dataIndex: "date",
    key: "date",
    scopedSlots: { customRender: "dateScope" },
  },
  {
    slots: { title: "sum" },
    dataIndex: "sum",
    key: "sum",
  },
  {
    slots: { title: "rate" },
    dataIndex: "rate",
    key: "rate",
  },
  {
    slots: { title: "restaurant" },
    dataIndex: "restaurant",
    key: "restaurant",
  },
  {
    slots: { title: "logo" },
    dataIndex: "logo",
    key: "logo",
    scopedSlots: { customRender: "logoScope" },
  },
  {
    key: "settings",
    scopedSlots: { customRender: "settingsScope" },
  },
];

const data = [
  {
    key: 1,
    date: moment("2021-02-12 17:46"),
    sum: "1500 ₽",
    rate: "PRO",
    restaurant: "Казбек",
    logo: logoImg,
  },
];

const pagination = {
  pageSize: 5,
  showSizeChanger: true,
  showQuickJumper: true,
  pageSizeOptions: ["5", "10", "15", "20", "25"],
};

export default {
  name: "PaymentHystory",
  data() {
    return {
      data,
      columns,
      pagination,
    };
  },
  components: {
    IconBase,
    IconEditGear,
  },
  methods: {
    editClick: function (key) {
      alert("форма редактирования, key = " + key);
    },
  },
};
</script>

<style scoped>
.customTitle {
  color: #1890f0;
}

.component-header {
  display: flex;
  align-items: left;
}
</style>
